import { useEffect, useState } from "react";
import { useAppState } from "../../contexts/appContext";
import { json, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Header from "../Header/Header";

const BASE_URL = process.env.REACT_APP_API_URL;

const Admin = () => {
    const navigate = useNavigate();
    const state = useAppState();
    const [arts, setArts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (state.roles.find((i) => (i.name == "admin")) == undefined) {
            navigate("/collectibles");
        }
    }, [state.signedIn, state.loading]);

    const changeHandler = (e) => {
        setIsSuccess(false);
        setMessage("");
        setArts(JSON.parse(e.target.value));
    };

    const uploadArtsHandler = async () => {
        setLoading(true);
        try {
            const resp = await fetch(`${BASE_URL}v1/admin/arts/bulk-minted`, {
                method: "POST",
                body: JSON.stringify({ data: arts }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + state.access_token,
                },
            });
            const jsonRes = await resp.json();
            if (jsonRes.success) {
                setMessage("Successfuly completed");
                setIsSuccess(true);
            } else {
                setMessage("Api request failed");
                setIsSuccess(false);
            }
            setArts([]);
            setLoading(false);

        } catch (error) {
            setMessage("Api request failed");
            setArts([]);
            setIsSuccess(false);
        }
    };
    const syncApiHandler = async () => {
        setLoading(true);
        try {
            const resp = await fetch(`${BASE_URL}v1/admin/arts/sync-minted`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + state.access_token,
                },
            });
            const jsonRes = await resp.json();
            if (jsonRes.success) {
                setMessage("Successfuly completed");
                setIsSuccess(true);
            } else {
                setMessage("Api request failed");
                setIsSuccess(false);
            }
            setLoading(false);
        } catch (error) {
            setMessage("Api request failed");
            setIsSuccess(false);
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Loader isLoading={loading} />
            <div className="container py-6">
                <h3>Admin Features</h3>
                <br />
                <div className="col-4">
                    <p>Sync minted</p>
                    <button
                        disabled={loading}
                        className="d-block btn-small w-100 btn btn-secondary text-uppercase"
                        onClick={syncApiHandler}
                    >
                        start sync
                    </button>
                </div>
                <hr />
                <br />
                <div className="col-12">
                    <p>Upload bulk minted arts</p>
                    <textarea
                        style={{ width: "100%" }}
                        rows="10"
                        onChange={changeHandler}
                        placeholder={`Example Data:
                        [{
                            "f_id": "",
                            "token_id": "",
                            "contract_address": "",
                            "chain_name": "",
                            "current_token_owner": "",
                            "old_token_owner": "",
                            "status": "claimed",
                            "is_minted": false,
                            "stripeTxDetails": ""
                        }]`}
                    />
                    <button
                        onClick={uploadArtsHandler}
                        disabled={arts.length == 0 || loading}
                        className="d-block btn-small w-100 btn btn-secondary text-uppercase"
                    >
                        {" "}
                        Upload
                    </button>
                </div>
                {message ? (
                    <p
                        style={{
                            width: "100%",
                            textAlign: "center",
                            margin: "20px",
                            color: isSuccess ? "#4bb5b3" : "#FA113D",
                        }}
                    >
                        {message}
                    </p>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Admin;
