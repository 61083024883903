import React, { createContext, useState, useContext, useEffect } from "react";
import jwt_decode from "jwt-decode";

const AppContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);

function AppProvider({ children }) {
    const [userDetails, setUserDetails] = useState({
        email: "",
        passCode: "",
        signedIn: false,
        loading: true,
        roles: [],
    });
    let updateState = (state) => {
        setUserDetails({ ...state });
    };
    useEffect(() => {
        const token = sessionStorage.getItem("access_token");
        if(!token){
            updateState({ loading: false })
            return
        }
        if (token) {
            updateState({ loading: true })
            var decoded = jwt_decode(token);
            const { exp } = decoded;
            if (exp < (new Date().getTime() + 1) / 1000) {
                updateState({ loading: false })
                return false;
            }
            updateState({
                ...userDetails,
                roles: decoded.roles,
                signedIn: true,
                email: decoded.email_address,
                is_custodial: decoded.is_custodial,
                id: decoded.id,
                access_token: token,
                loading: false,
            });
        }
    }, []);
    return (
        <AppContext.Provider value={userDetails}>
            <AppDispatchContext.Provider value={setUserDetails}>
                {children}
            </AppDispatchContext.Provider>
        </AppContext.Provider>
    );
}
const useAppState = () => useContext(AppContext);
const useAppDispatch = () => useContext(AppDispatchContext);
export { AppProvider, useAppState, useAppDispatch };
