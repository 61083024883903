
function SecondaryButton(props) {
    return (
        <button
            {...props}
            type="button"
            className="btn btn-secondary btnMidMinWidth text-uppercase"
            onClick={props.onclick}>
            {props.text}
        </button>
    )
}

export default SecondaryButton;