import React, { useEffect } from "react";
import { useAppState } from "../contexts/appContext";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const state = useAppState();

  useEffect(() => {
    if (!state.signedIn && !state.loading) {
      navigate("/");
    }
  }, [state.signedIn, state.loading]);

  if (state.loading) {
    return <div>loading</div>;
  } else {
    return <>{children}</>;
  }
};
export default ProtectedRoute;
