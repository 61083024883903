import "react-tooltip/dist/react-tooltip.css";
import React from "react";
import ReactDOM from "react-dom/client";
import Login from "./components/Login";
import VerifyPasscode from "./components/VerifyPasscode";
import Collection from "./components/art/Collection";
import Admin from "./components/Admin/Admin";
import ArtDetails from "./components/art/ArtDetails";
import Error from "./components/Error";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "./scss/App.scss";
import { AppProvider } from "./contexts/appContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";

const App = () => {
  return (
    <div className="App">
      <AppProvider>{<Outlet />}</AppProvider>
    </div>
  );
};

// routing config
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "verify",
        element: (
          <PublicRoute>
            <VerifyPasscode />
          </PublicRoute>
        ),
      },
      {
        path: "/collectibles",
        element: (
          <ProtectedRoute>
            <Collection />
          </ProtectedRoute>
        ),
      },
      {
        path: "/admin",
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: "/collectible/:id",
        element: (
          <ProtectedRoute>
            <ArtDetails />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
