
import AuthNav from "./Header/AuthNav";

function MyAccount({children}) {
  return (
    <>
      <div className="phStickyWrap phVi w-100">
        <header id="pageHeader" className="position-absolute w-100 bg-white">
          <AuthNav />
        </header>
      </div>
      <main>
        <article className="loginBlock hdIsVii d-flex w-100">
          <div className="lbAlignWrap d-flex align-items-center w-100">
            <div className="container py-6">
              <div className="row pt-10 pt-md-0 pt-lg-5 pt-xl-10">
                <div className="custom-row-login">
                  <div className="col-12">
                    <div className="extraWrap">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="login"
                          role="tabpanel"
                          aria-labelledby="login-tab"
                        >
                         {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </main>
    </>
  );
}
export default MyAccount;
