import { useState } from "react";
import { useAppDispatch, useAppState } from "../contexts/appContext";
import MyAccount from "./MyAccount";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_URL;

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const requestOtpHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('')
    try {
      const resp = await fetch(`${BASE_URL}v1/public/user/request-otp`, {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_ND_API_KEY,
        },
      });
      const jsonRes = await resp.json();
      if (jsonRes.success) {
        navigate(`/verify?email=${email}`)
        dispatch({
          ...state,
          email: email,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setError(jsonRes.message)       
      }
    } catch (e) {
      setError('Something went wrong.')
      setLoading(false);
    }
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
    <MyAccount>
      <h3 className="form-title mb-5">SIGN IN</h3>
      <form className="auth-form" onSubmit={requestOtpHandler}>
        <div className="form-group position-relative mb-3">
          <label className="d-block mb-0">
            <strong className="d-block font-weight-normal mb-1">
              Email<span className="text-brown">*</span>
            </strong>
            <div className="otp">
              <input
                onChange={onChangeHandler}
                value={email}
                type="email"
                className="form-control formControlMedium d-block w-100"
                placeholder="Enter your email"
              />
            </div>
          </label>
          {error ? (
            <p style={{ color: "#fc100d", fontSize: "12px" }}>{error}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="btnWrap mt-9">
          <button
            className="d-block w-100 btn btn-secondary text-uppercase"
            htmlType="submit"
            disabled={loading}
          >
            Sign in / Register
          </button>
        </div>
      </form>
    </MyAccount>
</>
  );
};

export default Login;
