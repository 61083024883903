import React from "react";
import { Link } from "react-router-dom";
function ShopItemCard(props){
    let showRibbon = props.hasRibbon;
    return(
        <div className="col-12 col-sm-6 col-lg-3">
            {/* <!-- 	shopItemColumn --> */}
            <article className="shopItemColumn position-relative mb-6 mb-lg-9 mb-xl-13 mx-auto">
                {showRibbon ? <div className="ribbon">Featured</div> : '' }
                <div className="imgHolder position-relative mb-4 overflow-hidden">
                    <img src={props.itemImage} className="img-fluid w-100" alt=" description"/>
                    <Link to={`/collectible/${props.id}`} className="sicBtnCart fontAlter font-weight-bold position-absolute btn btn-secondary text-uppercase d-block w-100">View</Link>
                </div>
                <h3 className="mb-2">
                  {props.itemName}
                </h3>
            </article>
        </div>
    )
}
export default ShopItemCard;