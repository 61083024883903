import EllipsisText from "react-text-overflow-middle-ellipsis";
import SecondaryButton from "../Buttons/SecondaryButton";
import ReactCountryFlag from "react-country-flag";
import Header from "../Header/Header";
import Loader from "../Loader";
import { useState, useEffect } from "react";
import { useAppState } from "../../contexts/appContext";
import { useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import flags from "../../assets/flags.json";
import Error from "../Error";
const BASE_URL = process.env.REACT_APP_API_URL;

const ArtworkDetails = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const state = useAppState();

  const transferReqHandler = async () => {
    setLoading(true)
    try {
      const resp = await fetch(`${BASE_URL}v1/user/arts/transfer-req`, {
        method: "POST",
        body: JSON.stringify({ receiver_address: receiverAddress, user_art_detail_id: data.id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.access_token,
        },
      });
      const jsonRes = await resp.json();
      setLoading(false)
      if(jsonRes.success){
        setModal(!modal);
      }else{
        errorMessage(jsonRes.message)
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage("Something went wrong")
    }
  }

  return (
    <>
      <Loader isLoading={loading} />
      <div className="col-12 col-lg-5">
        <div className="prDecriptionWrap">
          <h2 className="h2Medium mb-2">{data?.art_detail?.title}</h2>
          <div className="reViewWrap d-flex align-items-center mb-1">
            {data?.art_detail?.artist}
          </div>
          <strong className="d-block font-weight-normal prPrice text-gray777 mb-4">
            <div className="flag">
              <ReactCountryFlag
                className="emojiFlag"
                countryCode={
                  flags.filter((f) => f.name === data?.art_detail?.country)[0]
                    ?.code
                }
                style={{
                  fontSize: "24px",
                  lineHeight: "1",
                }}
                aria-label="United States"
                svg
              />
              <span className="ml-2">{data?.art_detail?.country}</span>
            </div>
          </strong>
          <p className="mb-4 item-detail-description">
            {data?.art_detail?.description}
          </p>
          {data?.token_id ? (
            <table className="apDataTable apDataTableII text-gray777 w-100 mb-6">
              <tbody>
                <tr>
                  <td className="text-secondary">Token ID:</td>
                  <td>{data?.token_id ? data?.token_id : "N/A"} </td>
                </tr>
                <tr>
                  <td className="text-secondary">Contract:</td>
                  <td>
                    <div style={{ width: "130px" }}>
                      {data?.account ? (
                        <a href="/" className="wallet-address mb-0" id="wallet">
                          <EllipsisText
                            text={data?.account}
                            className={"centerText "}
                          />
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-secondary">Chain:</td>
                  <td>
                    {data?.minted_art_detail?.chain_name
                      ? data?.minted_art_detail?.chain_name
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>
              Your Digital Collectible will be available within the next 48
              hours once it has been added to the blockchain. The "Transfer"
              feature will then be available. Please check back soon.
            </p>
          )}
          <div className="carterWrap">
            <div className="flex-buttons">
              <span id={data?.token_id ? "" : "transfer-tooltip"}>
                <SecondaryButton
                  disabled={!data?.token_id || data?.art_withdraw_request}
                  text="Transfer"
                  onclick={() => {
                    setModal(val => !val);
                  }}
                />
                <ReactTooltip
                  anchorId="transfer-tooltip"
                  place="bottom"
                  variant="dark"
                  content="Transfer will be enabled soon!"
                // &#128064
                />{" "}
              </span>
              {data?.token_id ? (
                <SecondaryButton
                  disabled={!data?.token_id}
                  text="view on etherscan"
                />
              ) : (
                <></>
              )}
            </div>
            <div
              className={`collapse hdSearchFieldCollapse position-fixed w-100 h-100 d-flex align-items-center"
      id="hdSearchFieldCollapse ${modal ? "show" : ""}`}
            >
              {/* <!-- hdsfcHolder --> */}
              <div className="hdsfcHolder w-100 mx-auto py-4 px-3">
                <div className="flexbox">
                  <div className="modal-body pt-4 px-6 pb-14">
                    <div className="modal-head d-flex align-items-start justify-content-between mb-10 mt-3">
                      <h3 className="text-capitalize mb-0 main-title">
                        Transfer
                      </h3>

                      <button
                        className="hdSearchClose d-flex align-items-center justify-content-center close-modal"
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        x
                      </button>
                    </div>
                    <div className="modal-body-content px-0 mt-8">
                      <div className="item-thumbnail">
                        <div className="item-thumb">
                          <img
                            src={data?.art_detail?.display_media_url}
                            className="img-fluid w-100"
                            alt="description"
                          />
                        </div>

                        <div className="item-short-description">
                          <h5 className="mb-0 font-weight-bold">
                            {data?.art_detail?.title}
                          </h5>
                          <span>
                            {data?.art_detail?.artist} -{" "}
                            {data?.minted_art_detail?.chain_name}
                          </span>
                        </div>
                      </div>
                      <div className="form-group position-relative mb-3 mt-8">
                        <label className="d-block mb-0">
                          <strong className="d-block font-weight-normal mb-1">
                            Recipient Wallet
                          </strong>
                          <input
                            value={receiverAddress}
                            onChange={e => {
                              setErrorMessage("")
                              setReceiverAddress(e.target.value)
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Enter wallet address"
                          />
                        </label>
                      </div>
                      <div className="mx-auto text-center mt-8">
                        <SecondaryButton
                          text="Transfer"
                          disabled={receiverAddress == ''}
                          onclick={transferReqHandler}
                        />
                      </div>
                      {
                        errorMessage ?
                          <p style={{ width: '100%', textAlign: 'center', color: '#FA113D' }}>{errorMessage}</p>
                          : <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ArtDetails = () => {
  const params = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const state = useAppState();
  useEffect(() => {
    setLoading(true);
    getUserArts(params.id);
  }, [params.id]);

  const getUserArts = async (id) => {
    try {
      const resp = await fetch(`${BASE_URL}v1/user/arts/art-by-id?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.access_token,
        },
      });
      const jsonRes = await resp.json();
      setLoading(false);
      setData(jsonRes.object);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          {data?.id ? (
            <>
              <Header />
              <main>
                <article className="shopContentBlock pt-7 pb-7 pb-md-9 pt-lg-10 pb-lg-14 pt-xl-16 pb-xl-22">
                  <div className="container py-7">
                    <div className="row pt-2 my-2 my-lg-7 pt-lg-7 align-items-center">
                      <div className="col-12 col-md-9 col-lg-7 pt-2 mb-7 mb-lg-0">
                        <div className="order-2 mb-7 mb-md-0">
                          <div className="collection-parent">
                            <div className="collection-img-container">
                              <div className="marge"></div>
                              <img
                                src={data?.art_detail?.display_media_url}
                                className="img-fluid w-100 middle"
                                alt="art"
                              />
                              <div className="marge"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {data?.id ? <ArtworkDetails data={data} /> : <></>}
                    </div>
                  </div>
                </article>
              </main>
            </>
          ) : (
            <Error />
          )}
        </>
      )}
    </>
  );
};

export default ArtDetails;
