import { useAppState } from "../contexts/appContext";

const Loader = ({isLoading}) => {
    if (isLoading) {
        return (
            <div className="loader-container">
                <div id="loading-bar-spinner" className="spinner">
                    <div class="spinner-icon"></div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default Loader