import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppState } from "../contexts/appContext";
import jwt_decode from "jwt-decode";
import MyAccount from "./MyAccount";
import { useLocation } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_API_URL;

const RE_DIGIT = new RegExp(/^\d+$/);

const PasscodeInput = ({ value, valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };
  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e) => {
    const { target } = e;
    const prevInputEl = target.previousElementSibling;
    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div id="inputs">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="digit"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
};

const Passcode = (props) => {
  const state = useAppState();
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const email = query.get("email");
    if (!email) {
      navigate("/");
    }
  }, []);

  const verifyHandler = async (e) => {
    e.preventDefault();
    if (otp.length == 6) {
      setError('')
      setLoading(true);
      try {
        const query = new URLSearchParams(location.search);
        const email = query.get("email");
        const resp = await fetch(`${BASE_URL}v1/public/user/verify-otp`, {
          method: "POST",
          body: JSON.stringify({ email: email, otp: otp }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await resp.json();
        if (jsonRes.success) {
          sessionStorage.setItem("access_token", jsonRes.access_token);
          var decoded = jwt_decode(jsonRes.access_token);
          const { exp } = decoded;
          if (exp < (new Date().getTime() + 1) / 1000) {
            return false;
          }
          dispatch({
            ...state,
            roles: decoded.roles,
            signedIn: true,
            email: decoded.email_address,
            is_custodial: decoded.is_custodial,
            id: decoded.id,
            access_token: jsonRes.access_token,
          });
          setLoading(false);
          setOtp("");
          navigate("/collectibles");
        } else {
          setLoading(false);
          setError(jsonRes.message)
        }
      } catch (e) {
        setError('Something went wrong.')
        setLoading(false);
      }
    }
  };

  return (
    <MyAccount>
      <h3 className="form-title mb-5">Passcode </h3>
      <form className="auth-form" onSubmit={verifyHandler}>
        <div className="form-group position-relative mb-3">
          <label className="d-block mb-0">
            <strong className="d-block font-weight-normal mb-1">
              Enter your 6-digit passcode sent to your email
              <span className="text-brown">*</span>
            </strong>
            <PasscodeInput value={otp} valueLength={6} onChange={onChange} />
          </label>
          {error ? (
            <p style={{ color: "#fc100d", fontSize: "12px" }}>{error}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="btnWrap mt-9">
          <button
            className="d-block w-100 btn btn-secondary text-uppercase"
            htmlType="submit"
            disabled={loading}
          >
            Verify
          </button>
        </div>
      </form>
    </MyAccount>
  );
};

export default Passcode;
