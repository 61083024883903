import ArtItemCard from "./ArtItemCard";
import LoadMore from "../Utilities/LoadMore";
import Header from "../Header/Header";
import { useAppState } from "../../contexts/appContext";
import { useEffect, useState } from "react";
import Loader from "../Loader";

const BASE_URL = process.env.REACT_APP_API_URL;

function Collection() {
  const state = useAppState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading()
    getUserArts();
  }, []);

  const getUserArts = async () => {
    try {
      const resp = await fetch(`${BASE_URL}v1/user/arts/get-user-arts`, {
        method: "POST",
        body: JSON.stringify({
          page: 1,
          size: 10,
          user_id: state.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.access_token,
        },
      });
      const jsonRes = await resp.json();
      setData(jsonRes);
      setLoading(false)
    } catch (e) {
      setData([]);
      setLoading(false)
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading}/>
      ) : (
        <>
          <Header />
          <div id="pageWrapper">
            <main>
              {/* <!-- shopContentBlock --> */}
              <section className="shopContentBlock pt-7 pb-7 pb-md-9 pt-lg-10 pb-lg-14 pt-xl-16 pb-xl-22">
                <div className="container">
                  <div className="row pt-2 my-2 my-lg-7 pt-lg-7">
                    <div className="col-lg-12">
                      <header className="teeSideHead mb-6 mb-lg-11">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-8">
                            <h1 className="text-capitalize mb-0 main-title">
                              My Collectibles
                            </h1>
                          </div>
                        </div>
                      </header>
                      <div className="row">
                        {data?.data?.map((item) => {
                          return (
                            <ArtItemCard
                              itemImage={item?.art_detail?.display_media_url}
                              itemName={item.art_detail.title}
                              id={item.id}
                              hasRibbon={false}
                            />
                          );
                        })}
                        {data.currentPage < data.totalPages ? (
                          <div className="col-12 text-center">
                            <LoadMore />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </>
      )}
    </>
  );
}

export default Collection;
