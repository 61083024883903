import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

function AuthNav() {
  return (
    <div className="hdHolder headerFixer auth-nav">
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light d-block px-0 pt-2 pb-2 pt-md-2 pb-md-2 pt-lg-4 pb-lg-4">
          <div className="row">
            <div className="col-12 text-center">
              {/* <!-- logo --> */}
              <div className="logo logoVii mx-auto my-4">
                <Link to="/">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="NftyDreams DAO"
                  />
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
export default AuthNav;
