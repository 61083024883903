import Navbar from "./Navbar";
// import SearchForm from "./SearchForm";
function Header() {
  return (
    <div className="phStickyWrap phVi w-100">
      <header id="pageHeader" className="position-absolute w-100 bg-white">
            <Navbar/>
      </header>
    </div>
  );
}
export default Header;
