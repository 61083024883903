import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import userAvatar from "../../images/user.png";
import { useAppState, useAppDispatch } from "../../contexts/appContext";
import SecondaryButtonSmall from '../Buttons/SecondaryButtonSmall'
import { useState, useEffect } from "react";
import { useNavigate} from 'react-router-dom'
function Navbar() {
  const state = useAppState();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // sticky header class
  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  //here 96(px) - height of current header
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 5);
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const signoutHandler = () => {
    sessionStorage.clear()
    dispatch({
      ...state,
      signedIn: false
    })
    navigate('/')
  }
  
  return (
    <div className={isScrollValueMoreThanHeaderHeight ? "hdHolder headerFixer fixedPosition" : "hdHolder headerFixer "}>
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light d-block px-0 pt-2 pb-2 pt-md-2 pb-md-2 pt-lg-4 pb-lg-4">
          <div className="row">
            <div className="col-4 col-sm-3 col-lg-2">
              {/* <!-- logo --> */}
              <div className="logo logoVii mt-lg-1">
                <Link to="/">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Muzze || Art &amp; History Museum"
                  />
                </Link>
              </div>
            </div>
            <div className="col-8 col-sm-9 col-lg-10 position-relative d-flex justify-content-end pt-lg-1">
              <div className="user-email mb-0">
                <img src={userAvatar} alt="avatar" className="user-avatar" />
                <div className="user-initials">
                  <p>{state.email}</p>
                </div>
                <SecondaryButtonSmall onClick={signoutHandler} text="Sign out" />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
export default Navbar;
