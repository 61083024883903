import { useRouteError } from "react-router-dom";

const Error = () => {
    let error = useRouteError();
    return(
        <div className="error">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <h3>
            <i>{error.statusText || error.message}</i>
          </h3>
        </div>
    )
}

export default Error